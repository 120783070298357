<template>
  <vca-column>
    <vca-card v-if="hasSystemPermission()" class="shadowed">
      <CrewSelect ref="crew" v-model="value" />
    </vca-card>
    <vca-card class="shadowed">
      <vca-field :label="$t('taking.form.name.header')">
        <h3>{{ $t("taking.form.name.label") }}</h3>
        <vca-input
          v-model="value.name"
          label=""
          :rules="$v.value.name"
          ref="name"
          :errorMsg="$t('taking.form.name.errorMsg')"
          :placeholder="$t('taking.form.name.placeholder')"
        >
        </vca-input>
        <h3>{{ $t("taking.form.date_of_taking.header") }}</h3>
        <vca-input-date
          ref="date_of_taking"
          v-model="date_of_taking"
          :default="Date.now() / 1000"
          :language="this.$i18n.locale"
          first
          :rules="$v.value.date_of_taking"
          :errorMsg="$t('taking.form.date_of_taking.errorMsg')"
          :placeholder="$t('taking.form.date_of_taking.placeholder_date')"
        />
        <div>
          <h3>{{ $t("taking.form.sources.label") }}</h3>
          <vca-dropdown
            :disabled="no_income_disable"
            v-model="sources"
            ref="sources"
            :multiple="true"
            :rules="$v.value.sources"
            :errorMsg="$t('taking.form.sources.errorMsg')"
            :options="sourcesList"
            label=""
            :placeholder="$t('taking.form.sources.placeholder')"
          />
        </div>
        <TakingEditSource v-model="value" ref="source" />
      </vca-field>
    </vca-card>
  </vca-column>
</template>
<script>
import { mapGetters } from "vuex";
import CrewSelect from "@/components/finance/takings/form/CrewSelect.vue";
import TakingEditSource from "./TakingEditSource.vue";
export default {
  name: "TakingEditForm",
  components: { CrewSelect, TakingEditSource },
  props: {
    value: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      valueLength: this.value.sources.length,
    };
  },
  validations() {
    return this.validation;
  },
  methods: {
    validate() {
      this.$refs.name.validate();
      this.$refs.crew.validate();
      this.$refs.sources.validate();
      if (this.value.sources.length > 0) {
        this.$refs.source.validate();
      }
    },
  },
  computed: {
    ...mapGetters({
      hasSystemPermission: "user/roles/hasSystemPermission",
      validation: "takings/validations",
    }),
    no_income_disable() {
      return this.value.state.no_income;
    },
    sourcesList: {
      get() {
        return this.$store.state.takings.sources.filter((el) => {
          el.title = this.$t(el.title);
          el.subtitle = this.$t(el.subtitle);
          el.label = this.$t(el.label);
          return true;
        });
      },
    },
    sources: {
      set(value) {
        if (this.valueLength < value.length) {
          const lastInDropdown = value[value.length - 1];
          this.value.sources.push({
            value: lastInDropdown.value,
            money: { amount: 0, currency: "EUR" },
            edit: true,
            id: "",
          });
        } else {
          var valueList = [];
          value.forEach((element) => {
            valueList.push(element.value);
          });
          var new_value = [];
          this.value.sources.forEach((element) => {
            if (valueList.includes(element.value)) {
              new_value.push(element);
            } else {
              if (element.id !== "") {
                element.update_state = "deleted";
                new_value.push(element);
              }
            }
          });
          this.value.sources = new_value;
        }
        this.valueLength = this.value.sources.length;
      },
      get() {
        return this.value.sources;
      },
    },
    date_of_taking: {
      get() {
        return this.value.date_of_taking;
      },
      set(value) {
        let date = new Date(this.value.date_of_taking * 1000);
        let newDate = new Date(value * 1000);
        date.setFullYear(
          newDate.getFullYear(),
          newDate.getMonth(),
          newDate.getDate()
        );

        let newValue = this.value;
        newValue.date_of_taking = date.getTime() / 1000;
        this.value = newValue;
      },
    },
  },
};
</script>
